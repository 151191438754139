<p>
	Dynamically add the field value into the prompt with <code>
		{'{{field.value|raw}}'}
	</code>
	. The field also provides
	<code>{'{{field.name}}'}</code>
	,
	<code>{'{{field.label}}'}</code>
	and
	<code>{'{{field.instructions}}'}</code>
	. To access the current language, use
	<code>{'{{currentSite.language}}'}</code>
	.
</p>

<style lang="scss">
	code {
		font-style: italic;
	}
</style>
